import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { TextFieldInnerIconButton } from "@/components/buttons/TextFieldInnerButton";
import { TextFieldWithInnerButton } from "@/components/TextField";
import useWindowSize from "@/hooks/useWindowSize";
import { resetIssuedReportLogListState } from "@/redux/logs/actions";
import { getExtraSearchType, getIssuedReportLogListState, getSearchType } from "@/redux/logs/selectors";
import { fetchMoreIssuedReportLogListThunk } from "@/redux/logs/thunk";
import { SearchType } from "@/redux/logs/types";
import { lengthsInNumbers } from "@/styles/values";
import { event } from "@/utils/analytics";
import { getUrl } from "@/utils/routes";

const InnerContainer = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
`;

export interface AddressSearchField {
  query?: string;
  searchType?: SearchType;
  identityCode?: string;
  frontIdentityCode?: string;
  backIdentityCode?: string;
}

export const AddressSearchSchema = yup.object<AddressSearchField>({
  query: yup.string().required("검색어를 입력해주세요.").min(1, "검색어를 1글자 이상 작성해 주세요."),
});

export const QuerySearchSchema = yup.object<AddressSearchField>({
  query: yup.string().required("검색어를 입력해주세요.").min(2, "검색어를 2글자 이상 작성해 주세요."),
  identityCode: yup.string().min(6, "생년월일을 6글자 작성해 주세요.").max(6, "생년월일을 6글자 작성해 주세요."),
  frontIdentityCode: yup
    .string()
    .min(6, "법인등록번호 앞 6자리를 입력해주세요.")
    .max(6, "법인등록번호 앞 6자리를 입력해주세요."),
  backIdentityCode: yup
    .string()
    .min(7, "법인등록번호 뒷 7자리를 입력해주세요.")
    .max(7, "법인등록번호 뒷 7자리를 입력해주세요."),
});

const IssuedReportSearch = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searched, setSearched] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramQuery = searchParams.get("query") ?? "";
  const paramSearchType = (searchParams.get("searchType") as SearchType) ?? "address";
  const paramIdentityCode = searchParams.get("identityCode") ?? "";

  const searchType = useSelector(getSearchType);
  const extraSearchType = useSelector(getExtraSearchType);
  const { fetchState: issuedReportLogListFetchState } = useSelector(getIssuedReportLogListState);

  const isMobile = useMemo(() => width <= lengthsInNumbers.desktop, [width]);

  const [backButtonClicked, setBackButtonClicked] = useState(false);

  useEffect(() => {
    window.addEventListener("popstate", handleBackButtonClick);
    return () => {
      window.removeEventListener("popstate", handleBackButtonClick);
    };
  }, []);

  useEffect(() => {
    if (backButtonClicked) {
      handleFetchIfParamsChanged();
      setBackButtonClicked(false);
    }
  }, [backButtonClicked]);

  const handleBackButtonClick = () => {
    setBackButtonClicked(true);
  };

  const handleFetchIfParamsChanged = () => {
    if (
      formik.values.query !== paramQuery ||
      formik.values.searchType !== paramSearchType ||
      formik.values.identityCode !== paramIdentityCode
    ) {
      dispatch(resetIssuedReportLogListState());
      event("search_issued_report");

      handleLoadMoreIssuedReportLogList(paramQuery, paramSearchType, paramIdentityCode);

      formik.setValues({
        query: paramQuery,
        searchType: paramSearchType,
        identityCode: parsedIdentityCode,
        frontIdentityCode: parsedFrontIdentityCode,
        backIdentityCode: parsedBackIdentityCode,
      });
    }
  };

  let parsedIdentityCode = "";
  let parsedFrontIdentityCode = "";
  let parsedBackIdentityCode = "";

  if (paramSearchType === "owner") {
    if (extraSearchType === "individual" && paramIdentityCode.includes("-")) {
      const [front] = paramIdentityCode.split("-");
      parsedIdentityCode = front;
    } else if (extraSearchType === "corporate" && paramIdentityCode.includes("-")) {
      const [front, back] = paramIdentityCode.split("-");
      parsedFrontIdentityCode = front;
      parsedBackIdentityCode = back;
    }
  }

  const formik = useFormik<AddressSearchField>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      query: paramQuery,
      searchType: paramSearchType,
      identityCode: parsedIdentityCode,
      frontIdentityCode: parsedFrontIdentityCode,
      backIdentityCode: parsedBackIdentityCode,
    },
    validationSchema: paramSearchType === "address" ? AddressSearchSchema : QuerySearchSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleLoadMoreIssuedReportLogList = useCallback(
    (query: string, searchType: SearchType, identityCode?: string) => {
      if (issuedReportLogListFetchState === "FETCHING" || !query || !searchType) {
        return;
      }

      if (searchType === "owner") {
        dispatch(
          fetchMoreIssuedReportLogListThunk({
            ownerName: query,
            ownerIdentityCode: identityCode || "",
          }),
        );
      } else {
        let registeredAddress: string | undefined;
        let ownershipTargetStakeholder: string | undefined;

        switch (searchType) {
          case "address":
            registeredAddress = query;
            break;
          case "interest":
            ownershipTargetStakeholder = query;
            break;
        }

        dispatch(
          fetchMoreIssuedReportLogListThunk({
            registeredAddress,
            ownershipTargetStakeholder,
          }),
        );
      }
    },
    [issuedReportLogListFetchState, dispatch],
  );

  const onSubmit = useCallback(
    ({ query, identityCode, frontIdentityCode, backIdentityCode }: AddressSearchField) => {
      let id = "";
      if (searchType === "owner") {
        switch (extraSearchType) {
          case "individual":
            id = identityCode ? `${identityCode}-*******` : "";
            break;
          case "corporate":
            id = frontIdentityCode && backIdentityCode ? `${frontIdentityCode}-${backIdentityCode}` : "";
            break;
        }
      }

      if (!query) {
        handleQueryReset();
        formik.setValues({
          query: "",
          searchType,
          identityCode: "",
          frontIdentityCode: "",
          backIdentityCode: "",
        });
      } else {
        setSearched(true);

        if (id && id.length > 0) {
          setSearchParams({ query, searchType, identityCode: id });
        } else {
          setSearchParams({ query, searchType });
        }
      }

      dispatch(resetIssuedReportLogListState());
      event("search_issued_report");

      handleLoadMoreIssuedReportLogList(query || "", searchType, id);
    },
    [searchType, extraSearchType],
  );

  const { values, errors, submitForm, setFieldValue, handleChange } = formik;

  const textFieldPlaceholder = useMemo(() => {
    switch (searchType) {
      case "address":
        return "도로명 주소, 건물명 또는 지번 입력";
      case "owner":
        return "소유주명 입력";
      case "interest":
        return "이해관계인명 입력";
    }
  }, [searchType]);

  const handleQueryTextFieldReset = useCallback(() => {
    setFieldValue("query", "");
    setFieldValue("frontIdentityCode", "");
    setFieldValue("backIdentityCode", "");
    setFieldValue("identityCode", "");
  }, [values.query]);

  const handleQueryReset = () => {
    setSearched(false);
    setFieldValue("query", "");
    dispatch(resetIssuedReportLogListState());
    navigate(getUrl("reportsIssued"));
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  return (
    <>
      <TextFieldWithInnerButton
        name="query"
        value={values.query}
        onChange={handleChange}
        fullWidth
        type="text"
        placeholder={textFieldPlaceholder}
        helperText={errors.query}
        error={!!errors.query}
        InputProps={{ style: { backgroundColor: "#fff" } }}
        innerButton={
          <TextFieldInnerIconButton type="button" onClick={handleQueryTextFieldReset}>
            <Close />
          </TextFieldInnerIconButton>
        }
        onKeyPress={handleKeyPress}
      />

      {searchType === "owner" && (
        <InnerContainer>
          <TextFieldWithInnerButton
            name={extraSearchType === "individual" ? "identityCode" : "frontIdentityCode"}
            value={extraSearchType === "individual" ? values.identityCode : values.frontIdentityCode}
            onChange={handleChange}
            type="text"
            placeholder={extraSearchType === "individual" ? "주민번호 앞자리" : "법인번호 앞자리"}
            helperText={extraSearchType === "individual" ? errors.identityCode : errors.frontIdentityCode}
            error={extraSearchType === "individual" ? !!errors.identityCode : !!errors.frontIdentityCode}
            fullWidth={isMobile}
            hideInnerButton
          />

          <TextFieldWithInnerButton
            name="backIdentityCode"
            value={extraSearchType === "individual" ? "*******" : values.backIdentityCode}
            onChange={handleChange}
            type="text"
            placeholder={extraSearchType === "individual" ? "*******" : "법인번호 뒷자리"}
            helperText={extraSearchType === "corporate" ? errors.backIdentityCode : ""}
            error={extraSearchType === "corporate" && !!errors.backIdentityCode}
            fullWidth={isMobile}
            hideInnerButton
            disabled={extraSearchType === "individual"}
          />
        </InnerContainer>
      )}

      <PrimaryButton onClick={submitForm}>{searched ? "재검색" : "검색"}</PrimaryButton>
    </>
  );
};

export default IssuedReportSearch;
